import { joinURL } from 'ufo'

// Временный middleware для перенаправления в CAS
// TODO
// редирект на стороне nginx
// или если редирект на стороне приложения,
// то настроить редирект в routeRules
// для этого необходимо урл CAS прописывать в переменные окружения
export default defineNuxtRouteMiddleware(async (to) => {
  const query = to.query as Record<string, string>

  // При получении ваучера перейти на страницу его регистрации
  const voucherId = query.voucher
  if (voucherId) {
    return navigateTo({
      name: 'voucher-id-activate',
      params: {
        id: voucherId,
      },
    })
  }

  const { APP_HOST } = useRuntimeConfig().public
  const { getBaseURL } = useAuth()

  const baseURL = await getBaseURL()

  function getServiceURL(
    host: string,
    path: string,
    query: Record<string, string>,
  ) {
    const url = new URL(path, host)
    for (const [k, v] of Object.entries(query)) {
      url.searchParams.set(k, v as string)
    }
    return url.toString()
  }

  // при не актуальных маршрутов авторизации редирект на CAS/auth/
  // маршруты сброса пароля на CAS/auth/<текущий путь>
  // маршрут редактирования профиля CAS/profile/
  // иначе редирект на CAS/<текущий путь>
  if (to.path.startsWith('/login') || to.path.startsWith('/signup')) {
    return navigateTo(
      {
        path: joinURL(baseURL, '/auth/'),
        query: {
          service: getServiceURL(APP_HOST, '/', query),
        },
      },
      {
        external: true,
      },
    )
  } else if (
    to.path.startsWith('/password-reset') ||
    to.path.startsWith('/password-restore')
  ) {
    return navigateTo(
      {
        path: joinURL(baseURL, '/auth/', to.path),
        query: {
          service: getServiceURL(APP_HOST, '/', query),
        },
      },
      {
        external: true,
      },
    )
  } else if (to.path.startsWith('/profile/edit')) {
    {
      return navigateTo(
        {
          path: joinURL(baseURL, to.path),
          query: {
            service: getServiceURL(APP_HOST, '/profile/', query),
          },
        },
        {
          external: true,
        },
      )
    }
  } else {
    return navigateTo(
      {
        path: joinURL(baseURL, to.path),
        query: {
          service: getServiceURL(APP_HOST, '/', query),
        },
      },
      {
        external: true,
      },
    )
  }
})
